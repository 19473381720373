@import "shared/styles/global";

.aside {
	z-index: 10000;

	display: flex;
	flex-direction: column;

	justify-content: space-between;
	width: 180px;
	height: 100vh;
	padding: 12px 8px 24px;

	color: white;

	background-color: #10111a;

	nav {
		display: flex;
		flex-direction: column;

		.logo {
			margin-bottom: 14px;
			margin-left: 4px;
		}
	}
}


.link {
	display: flex;
	gap: spacing(1);
	align-items: center;
	padding: spacing(1, 1);

	color: $c-dark-grey;

	text-decoration: none;
	
	&Active {
		border-radius: 2px;

		color: white;

		background-color: #283241;
	}
}

.help {
	display: flex;
	gap: spacing(1);
	align-items: center;
	justify-content: center;
	padding: spacing(1, 3);

	border-radius: 2px;

	color: white;

	text-decoration: none;

	background-color: rgba(255, 255, 255, 0.10);
}

.bottom {
	display: flex;
	flex-direction: column;

	gap: spacing(1);
}

.card {
	display: flex;
	flex-direction: column;
	padding: spacing(2);

	border-bottom: 1px solid $c-grey;

	border-radius: 2px;

	text-align: center;

	background: linear-gradient(95deg, rgba(78, 224, 54, 0.30) -26.18%, rgba(137, 213, 255, 0.30) 126.51%), #FFF;

	backdrop-filter: blur(7.5px);

	&Text {
		margin-bottom: spacing(4);
	}
}

.star {
	font-size: 32px;
}

.trophy {
	color: $c-dark-grey;

	&Wrapper {
		display: flex;
		gap: spacing(1);
		align-items: center;
		padding: spacing(1, 1);

		text-decoration: none;

		&:hover {
			color: white;

			.trophy {
				color: white;
			}

			.text {
				color: white;
			}
		}
	}
}

.extension {
	padding-right: 0;
	padding-bottom: spacing(1.5);
}

.chrome {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	gap: 10px;
	align-items: center;
	justify-content: center;

	width: 24px;
	height: 24px;

	border-radius: 4px;

	background: rgba(255, 255, 255, 0.90);
}

.cube {
	flex-shrink: 0;

	align-self: start;
}
