@import 'shared/styles/global';

.search {
	display: flex;
	gap: spacing(2);

	align-items: center;

	padding: spacing(1);
	border: 1px solid $c-grey;
    border-radius: spacing(2);

	&Open {
		background: white;
	}

	&Focus {
		border-color: $c-grey;
	}

	&Input {
		width: 100%;
		height: fit-content;

		max-height: 400px;

		font-size: 14px;
		font-family: $f-inter;
		line-height: 120%;

		resize: none;

		&Small {
			max-height: 36px;

			overflow-y: hidden;
		}

        &::placeholder {
            color: #494949;
        }
	}
}

.wrapper {
	flex: 1;
	padding-top: spacing(0.5);
	padding-left: spacing(2);
}
