@import 'shared/styles/global';

.chat {
    display: flex;
    flex-direction: column;
    gap: spacing(3);
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: spacing(0, 4);

    &Input {
        width: 100%;
        max-width: 920px;
    }
}

.container {
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    gap: 16px;
    align-self: stretch;
    height: 100%;
    padding: 32px 44px 16px;
}

.button {
	display: flex;

	align-items: center;
	justify-content: center;

	height: 48px;

	padding: spacing(1, 2);

	border-radius: 8px;
	
	text-align: center;

	background-color: $c-blue-new;

	cursor: pointer;

	&:hover {
		background: linear-gradient(314.69deg, #4ee036 -205.1%, #89d5ff -52.35%, #b6aaff 110.31%);
	}

	&Disabled {
		cursor: default;

		&:hover {
			background: $c-blue-new;
		}
	}

    &Center {
        align-self: center;
    }
}

.userMessage {
    display: flex;
    align-self: flex-end;
    margin-left: spacing(10);
    padding: 8px 14px;

    border-radius: 24px;

    white-space: pre-wrap;

    background: #F4F4F4;

    span {
        color: #212121;
    }
}

.assistantMessage {
    display: flex;
    margin-right: spacing(10);
    padding: 8px 14px;

    white-space: pre-wrap;

    span {
        color: #212121;
    }
}

.window {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    align-self: stretch;
    width: 67%;
    padding: 0 24px;

    &Wrapper {
        display: flex;
        height: calc(100% - 32px); 
    }
}

.messages {
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    align-self: stretch;
    overflow-y: scroll;

    scrollbar-width: none;
	
	&::-webkit-scrollbar {
		display: none;
	}
}

.sidebarWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-end;
    align-self: stretch;
    justify-content: center;

    width: calc(33% - 16px);
}
