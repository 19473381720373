@import 'shared/styles/global';

.sidebar {
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    align-self: stretch;
    padding: 16px 16px 8px;

    border-radius: 16px;

    background: #F5F9FF;
}

.filters {
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    align-self: stretch;
    padding: 8px 0;
}

.tags {
    display: flex;
    flex: 1 0 0;
    flex-wrap: wrap;
    gap: 6px 8px;
    align-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
}

.results {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    justify-content: center;
    padding-top: 8px;

    border-top: 1px solid rgba(134, 134, 134, 0.16);

    &Header {
        display: flex;
        align-items: center;
        align-self: stretch;
        justify-content: space-between;
        height: 40px;
        padding-left: 8px;
    }

    &Count {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        align-self: stretch;
        padding: 0 8px 8px;
    }

    &Row {
        display: flex;
        gap: 10px;
        align-items: center;
        align-self: stretch;
        height: 34px;
        padding: 8px 0;
    }

    &Icon {
        color: #1E78FF;
    }
}
